import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/Register";
import UserInfo from "../views/UserInfo";
import AdminDashboard from "../views/adminDashboard";
import OfflifePage from "../views/Offline";
import AdminCheckin from "../views/adminCheckin";

import firebase from "firebase";
import firebaseConfig from "../firebase/config";
const db = firebaseConfig.firestore();

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/userinfo",
    name: "UserInfo",
    component: UserInfo,
  },
  {
    path: "/backoffice/checkin",
    name: "Checkin",
    component: AdminCheckin,
    beforeEnter: (to, from, next) => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) {
          next("/");
        } else {
          await db
            .collection("admin")
            .where("email", "==", user.email)
            .get()
            .then((adminData) => {
              if (adminData.empty) {
                next("/");
                // this.$router.push({ name: "Home" });
              } else {
                next();
              }
            });
        }
      });
    },
  },
  {
    path: "/backoffice/dashboard",
    name: "Dashboard",
    component: AdminDashboard,
    beforeEnter: (to, from, next) => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) {
          next("/");
        } else {
          await db
            .collection("admin")
            .where("email", "==", user.email)
            .get()
            .then((adminData) => {
              if (adminData.empty) {
                next("/");
                // this.$router.push({ name: "Home" });
              } else {
                next();
              }
            });
        }
      });
    },
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
