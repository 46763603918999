import firebase from "firebase";

var firebaseConfig = {
    apiKey: "AIzaSyCI8Tv6R61WibMPmoCPJwsh9WBEQTu9bgE",
    authDomain: "freelife-df475.firebaseapp.com",
    databaseURL: "https://freelife-df475.firebaseio.com",
    projectId: "freelife-df475",
    storageBucket: "freelife-df475.appspot.com",
    messagingSenderId: "474462340065",
    appId: "1:474462340065:web:e57f4abeb585055293c8a0",
    measurementId: "G-KLE9J8X5MP"
};


export default firebase.initializeApp(firebaseConfig);
// export const users = database.ref("users");
// export const firstMeeting  = database.ref("meeting1")
// export const databaseURL = "https://freelife-df475.firebaseio.com/.json";