<template>
  <div class="container register">
    <div class="row justify-content-center">
      <div align="center">
        <img
          src="/images/meeting1.jpg"
          width="60%"
          alt=""
          class="logo-meeting"
        />
      </div>
      <div class="col-md-8 col-sm-12">
        <h2 class="mt-3 text-center text-white">ลงทะเบียน</h2>
        <hr />
        <div class="login-form">
          <form
            class="needs-validation"
            v-on:submit.prevent="updateUserProfile"
          >
            <div class="row">
              <div class="col-md-9 mb-3">
                <label for="name">ชื่อ - นามสกุล</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="name"
                  id="firstName"
                  placeholder=""
                  value=""
                  required=""
                />
                <div class="invalid-feedback">
                  Valid first name is required.
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="Gender">เพศ</label>
                <select class="form-control" v-model="gender" id="">
                  <option value="male">ชาย</option>
                  <option value="female">หญิง</option>
                  <option value="other">ไม่ระบุ</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 mb-3">
                <label for="name">อายุ</label>
                <input
                  v-model="age"
                  type="text"
                  class="form-control"
                  id="Age"
                  placeholder=""
                  value=""
                  required=""
                />
                <div class="invalid-feedback">
                  Valid first name is required.
                </div>
              </div>
              <div class="col-md-9 mb-3">
                <label for="Gender">วันเดือนปี เกิด</label>
                <datepicker
                  input-class="form-control"
                  v-model="date"
                ></datepicker>
              </div>
            </div>

            <div class="mb-3">
              <label for="email">Email <span class="text-muted"></span></label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="email"
                placeholder="you@example.com"
                disabled
              />
              <div class="invalid-feedback">
                Please enter a valid email address for shipping updates.
              </div>
            </div>

            <div class="form-group">
              <label
                >ขนาดเสื้อ |
                <a v-b-modal.modal-1 class="text-info"
                  >ดูขนาดเสื้อแบบละเอียด</a
                ></label
              >

              <select class="form-control" v-model="shirtSize" placleholder="S">
                <option value="ss">SS</option>
                <option value="s">S</option>
                <option value="m">M</option>
                <option value="l">L</option>
                <option value="xl">XL</option>
                <option value="xl">XXL</option>
                <option value="3xl">3XL</option>
                <option value="4xl">4XL</option>
                <option value="5xl">5XL</option>
                <option value="6xl">6XL</option>
              </select>

              <!-- <input
                type="tel"
                class="form-control"
                placeholder="XL , S"
                v-model="shirtSize"
              /> -->
            </div>

            <div class="mb-3">
              <label for="address">ที่อยู่</label>
              <input
                v-model="address"
                type="text"
                class="form-control"
                id="address"
                placeholder="142 หมู่"
                required=""
              />
              <div class="invalid-feedback">
                Please enter your shipping address.
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <addressinput-subdistrict
                  inputClass="custom-select d-block w-100"
                  v-model="subdistrict"
                />
              </div>
              <div class="col-md-6">
                <addressinput-district
                  inputClass="form-control d-block w-100"
                  v-model="district"
                />
              </div>
              <div class="col-md-6">
                <addressinput-province
                  inputClass="custom-select d-block w-100"
                  v-model="province"
                />
              </div>
              <div class="col-md-6">
                <addressinput-zipcode
                  inputClass="custom-select d-block w-100"
                  v-model="zipcode"
                />
              </div>
              <div class="col-md-12 mb-3">
                <div class="form-group">
                  <label>เบอร์โทรศัพท์</label>
                  <input
                    type="tel"
                    class="form-control"
                    placeholder=""
                    v-model="phone"
                  />
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="">คุณรู้จักเราได้อย่างไร (ช่องทาง)</label>
              <input
                v-model="chanelfollow"
                type="text"
                class="form-control"
                id=""
                placeholder="Facebook , เพื่อนที่รู้จัก"
                required=""
              />
            </div>
            <div class="mb-3">
              <label for="">ตัวละครที่ชื่นชอบ</label>
              <input
                v-model="favCharacter"
                type="text"
                class="form-control"
                id=""
                placeholder="ชวลิต"
                required=""
              />
            </div>

            <div class="mb-3">
              <label for="">ความคิดเห็น</label>
              <textarea
                class="form-control"
                name=""
                id=""
                cols="10"
                rows="5"
                v-model="comment"
              ></textarea>
            </div>

            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="accetprivacy"
                v-model="acceptData"
                @click="acceptPrivacy"
              />
              <label class="custom-control-label" for="accetprivacy"
                >ยอมรับเงื่อนไขข้อตกลง
                <a class="text-warning" v-b-modal.modal-2
                  >นโยบายข้อมูลส่วนบุคคล</a
                ></label
              >
            </div>

            <hr class="mb-4" />
            <button
              :disabled="!acceptData"
              class="btn btn-primary btn-lg btn-block"
              type="submit"
            >
              ลงทะเบียน
            </button>
          </form>
        </div>
      </div>
    </div>
    <br /><br />
    <b-modal id="modal-1" size="xl" title="ขนาดเสื้อ">
      <img src="/images/shirtS.jpg" width="100%" alt="" />
    </b-modal>
    <b-modal
      size="xl"
      id="modal-2"
      title="นโยบายการคุ้มครองข้อมูลส่วนบุคคล ฟรีไลพ์"
    >
      <b>1. การเก็บรวบรวมข้อมูลส่วนบุคคลอย่างจำกัด</b>
      <br />
      การเก็บข้อมูลของ ฟรีไลพ์ จะไม่เปิดเผยต่อบุคคลสารธารณะ หรือ บุคคลอื่น ๆ
      ที่ไม่ใช่มีความจำเป็น หรือเกี่ยวข้องอย่างเด็ดขาด
      และใช้เพื่อตามที่กฎหมายกำหนดเท่านั้น
      ซึ่งต้องแจ้งให้ผู้ใช้บริการทราบและขอความยินยอมก่อนเก็บรวบรวมข้อมูลส่วนบุคคล
      เช่น ชื่อ-นามสกุล อีเมล (e-mail) หมายเลขโทรศัพท์ ที่อยู่ อายุ
      โดยฟรีไลพ์จะรักษาข้อมูลเหล่านั้นไว้เป็นความลับ
      <br />
      <b>2. คุณภาพของข้อมูลส่วนบุคคล</b>
      ฟรีไลพ์ มีการจัดเก็บข้อมูลส่วนบุคคลของผู้ใช้บริการ
      เพื่อนำไปใช้ประโยชน์ในการติดต่อสื่อสาร หรือ
      ใช้เพื่อส่งมอบของราวัลในการเข้าร่วมกิจกรรมต่าง ๆ เพื่อใช้ในการระบุตัวตน
      โดยข้อมูลที่ได้ทำการจัดเก็บนั้น ฟรีไลพ์จะคำนึงถึงความถูกต้อง ครบถ้วน
      และความเป็นปัจจุบันของข้อมูล
      <br />
      <b>3. วัตถุประสงค์ในการจัดเก็บข้อมูลส่วนบุคคล </b>
      จัดเก็บ ใช้ ข้อมูลส่วนบุคคล เพื่อประโยชน์ใน การให้บริการแก่ผู้ใช้บริการ
      และการดำเนินการตามวัตถุประสงค์ของฟรีไลพ์ เพื่อใช้ในการติดต่อสื่อสาร
    </b-modal>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import firebase from "firebase";
import firebaseConfig from "../firebase/config";
const db = firebaseConfig.firestore();
export default {
  name: "Register",
  components: {
    Datepicker,
  },
  data() {
    return {
      acceptData: false,
      email: "",
      name: "",
      phone: "",
      gender: "",
      age: "",
      date: "",
      shirtSize: "",
      address: "",
      subdistrict: "",
      district: "",
      province: "",
      zipcode: "",
      chanelfollow: "",
      favCharacter: "",
      comment: "",
    };
  },
  beforeMount() {
    this.isAuth();
  },
  methods: {
    acceptPrivacy() {
      this.acceptData == false
        ? (this.acceptData = true)
        : (this.acceptData = false);
    },
    async createUniqueId() {
      let randomNumber = Math.floor(10000 + Math.random() * 90000);

      let existNumber = await db
        .collection("meeting1")
        .where("joinId", "==", randomNumber)
        .get()
        .then((joinId) => {
          return joinId;
        });

      if (existNumber.empty) {
        return randomNumber;
      } else {
        this.createUniqueId();
      }
    },
    async isAuth() {
      await firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) {
          this.$router.replace({
            name: "Home",
          });
        }
        let userId = user.uid;

        let userData = await db
          .collection("users")
          .where("userId", "==", userId)
          .get()
          .then((user) => {
            return user;
          });

        if (!userData.empty) {
          this.$swal("This account already has information.");
          this.$router.replace({
            name: "Home",
          });
        }
        this.email = user.email;
      });
    },
    async logout() {
      await firebase
        .auth()
        .signOut()
        .then((logouted) => {
          this.$router.replace({
            name: "Home",
          });
        })
        .catch();
    },
    async updateUserProfile() {
      if (
        !this.name ||
        !this.phone ||
        !this.date ||
        !this.gender ||
        !this.age
      ) {
        this.$swal("โปรดกรอกข้อมูลให้ครบถ้วน");
        return;
      }

      if (this.phone.length != 0 && this.name.length != 0) {
        await firebase.auth().onAuthStateChanged(async (user) => {
          let userData = {
            userId: user.uid,
            displayName: user.displayName,
            email: user.email,
            avatar: user.photoURL,
            name: this.name,
            phoneNo: this.phone,
            date: this.date,
            gender: this.gender,
            shirtSize: this.shirtSize,
            age: this.age,
            address: this.address,
            subdistrict: this.subdistrict,
            district: this.district,
            province: this.province,
            zipcode: this.zipcode,
            chanel: this.chanelfollow,
            favCharacter: this.favCharacter,
            comment: this.comment,
          };
          db.collection("users")
            .add(userData)
            .then(() => {
              this.$swal("Welcome");
              this.createUniqueId().then(async (joinid) => {
                await db
                  .collection("meeting1")
                  .add({
                    userId: user.uid,
                    joinId: joinid,
                    eventName: "Meet The King Festival",
                    userData: userData,
                    userSpare: 1,
                    checkin: 0,
                  })
                  .then(() => {
                    this.$router.push({
                      name: "UserInfo",
                    });
                  });
              });
            })
            .catch((e) => {
              this.$swal("Please try again");
            });
          // users
          //   .push({
          //     uid: user.uid,
          //     name: this.name,
          //     phoneNo: this.phone,
          //     displayName: user.displayName,
          //     email: user.email,
          //     avatar: user.photoURL,
          //   })
          //   .then((updated) => {
          //     console.log(updated);
          //   })
          //   .catch();
        });
      } else {
        this.$swal("Please enter your name or phone number.");
      }
    },
  },
};
</script>
<style lang="scss">
.register {
  color: #000;
}
.th-address-autocomplete {
  color: #000;
}
.register label {
  color: rgb(255, 255, 255);
}
.btn-black {
  background-color: #000 !important;
  color: #fff;
}
.th-address-label {
  width: 100%;
}
</style>