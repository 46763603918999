<template>
  <div>
    <div>
      <b-navbar toggleable="lg" type="dark" class="bg-primary">
        <b-navbar-brand href="#">Admin FreeLife</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item>
              <router-link to="/backoffice/dashboard"> Member </router-link>
            </b-nav-item>
            <b-nav-item>
              <router-link to="/backoffice/checkin"> Checkin </router-link>
            </b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-navbar-nav>
              <b-nav-item @click="logout">Logout</b-nav-item>
            </b-navbar-nav>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <b-container>
      <div class="mt-4">
        <b-tabs content-class="mt-3">
          <b-tab title="รายชื่อทั้งหมด" active @click="fetchallData">
            <div class="text-center mt-3">
              <h3>จำนวนผู้สมัครเข้าร่วมทั้งหมด {{ countAllUser }}</h3>
            </div>
            <br />
            <b-form-input
              v-model="search"
              placeholder="ค้นหาทั้งหมดในตาราง"
            ></b-form-input>
            <br />
            <div class="overflow-auto">
              <p class="mt-3">Current Page: {{ currentPage }}</p>

              <b-table
                :filter="search"
                striped
                hover
                :fields="fields"
                id="my-table"
                :items="userData"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template scope="" v-slot:cell(actions)="userData">
                  <b-button variant="danger" @click="deleteUser(userData)"
                    ><i class="fa fa-trash"></i
                  ></b-button>
                </template>
                <template>
                  
                </template>
              </b-table>
              <div class="text-center">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </b-tab>
          <b-tab title="ที่เข้าร่วมงานแล้ว" @click="tabActive">
            <div class="mt-4 text-center">
              <h3>จำนวนผู้เข้าร่วมงานทั้งหมด {{ countAllUser }}</h3>
            </div>
            <div>
              <b-button variant="info" @click="downloadJoinId"
                >ดาวน์โหลดรหัสเข้าร่วม</b-button
              >
            </div>
            <br />
            <b-form-input
              v-model="search"
              placeholder="ค้นหาทั้งหมดในตาราง"
            ></b-form-input>
            <br />
            <div class="overflow-auto">
              <p class="mt-3">Current Page: {{ currentPage }}</p>

              <b-table
                :filter="search"
                striped
                hover
                :fields="fields"
                id="my-table"
                :items="userDataJoined"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template scope="" v-slot:cell(actions)="userData">
                  <b-button variant="danger" @click="deleteUser(userData)"
                    ><i class="fa fa-trash"></i
                  ></b-button>
                </template>
                <template></template>
              </b-table>
              <div class="text-center">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-container>

    <!-- <download-csv :data="userData">
      <b-button variant="info">Export problem to CSV</b-button>
    </download-csv> -->
  </div>
</template>
<script>
import firebase from "firebase";
import { jsPDF } from "jspdf";
import firebaseConfig from "../firebase/config";
import supermarketFont from "@/assets/fonts/supermarket/supermarket-normal.js";
const db = firebaseConfig.firestore();
export default {
  name: "AdminDashboard",
  computed: {
    rows() {
      return this.userData.length;
    },
  },
  data() {
    return {
      countAllUser: "",
      userData: [],
      userDataJoined: [],
      search: "",
      joinedSearch: "",
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        {
          key: "joinId",
          label: "หมายเลขเข้าร่วม",
          sortable: true,
        },
        {
          key: "userData.name",
          label: "ชื่อ-นามสกุล",
          sortable: true,
        },
        {
          key: "userData.phoneNo",
          label: "เบอร์โทร",
          sortable: false,
        },
        {
          key: "userData.email",
          label: "Email",
          sortable: false,
        },
        {
          key: "userData.shirtSize",
          label: "ขนาดเสื้อ",
          sortable: true,
        },
        {
          key: "userData.gender",
          label: "เพศ",
          sortable: true,
        },
        {
          key: "userData.province",
          label: "จังหวัด",
          sortable: true,
        },
        // {
        //   key: "checkin",
        //   label: "เข้าร่วมงานแล้ว",
        //   sortable: true,
        // },
        {
          key: "userSpare",
          label:"ลงทะเบียนย้อนหลัง",
           sortable: true,
        },
        {
          key: "actions",
        },
      ],
      perPage: 10,
      totalItems: 10,
      currentPage: 1,
    };
  },
  created() {
    // this.countUser();
    this.getUserData();
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.totalItems += 10;
        console.log(this.totalItems);
        // this.getUserData().catch(error => {
        //   console.error(error)
        // })
      },
    },
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    downloadJoinId() {
      var generateData = function (userData) {
        var result = [];
        for (var i = 0; i < userData.length; i++) {
          if (userData[i].userData.name != null) {
            result.push({
              JoinIDForCut: userData[i].joinId + "",
              JoinID: userData[i].joinId + "",
              // Name: userData[i].userData.name,
              Gender: userData[i].userData.gender,
              Phone: userData[i].userData.phoneNo + "",
              ShirtSize: userData[i].userData.shirtSize,
            });
          }
        }
        return result;
      };

      function createHeaders(keys) {
        var result = [];
        for (var i = 0; i < keys.length; i += 1) {
          result.push({
            id: keys[i],
            name: keys[i],
            prompt: keys[i],
            width: 150,
            align: "center",
            padding: 0,
          });
        }
        return result;
      }

      var headers = createHeaders([
        "JoinIDForCut",
        "JoinID",
        // "Name",
        "Gender",
        "Phone",
        "ShirtSize",
      ]);

      const doc = new jsPDF({
        orientation: "landscape",
      });

      // doc.addFileToVFS('Supermarket.ttf',supermarketFont)
      // doc.addFont('Supermarket.ttf','Supermarket', 'normal')
      // doc.setFont('Supermarket')

      // console.log(generateData(this.userDataJoined));

      doc.table(1, 1, generateData(this.userData), headers, {
        autoSize: true,
      });

      doc.save("a4.pdf");
    },
    tabActive() {
      this.getUserDataJoined();
    },
    fetchallData(){
      this.getUserData();
    },
    getUserDataJoined() {
      let userDataJoinMeeting = [];
      db.collection("meeting1")
        .where("checkin", "==", 1)
        // .limit(10)
        .get()
        .then((user) => {
          this.countAllUser = user.size;
          console.log(user.size);
          user.forEach((u) => {
            userDataJoinMeeting.push(u.data());

            return userDataJoinMeeting;
          });
        });
      this.userDataJoined = userDataJoinMeeting;
    },
    getUserData() {
      let userDataInMeeting = [];
      db.collection("meeting1")
        // .limit(10)
        .get()
        .then((user) => {
          this.countAllUser = user.size;
          user.forEach((u) => {
            userDataInMeeting.push(u.data());

            return userDataInMeeting;
          });
        });

      this.userData = userDataInMeeting;
    },
    deleteUser(userData) {
      let name = userData.item.userData.name;
      console.log(name);
      this.$swal({
        title: "ยืนยันการลบ",
        text: `ต้องการลบ ${name} ?`,
        icon: "warning",
        dangerMode: true,
      }).then((deleted) => {
        if (deleted.isConfirmed) {
          db.collection("meeting1")
            .where("userId", "==", userData.item.userId)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref.delete();
              });
            })
            .then((deleteOk) => {
              this.$swal("Deleted !");
            });
        }
      });
    },
  },
};
</script>