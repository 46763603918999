<template>
  <div class="offline">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="error-template">
            <h1>Freelife - Meet The King Festival #1</h1>
            <h2>ขออภัย เราได้ปิดการรับสมัครเข้าร่วมแล้ว</h2>
            <div class="error-details">
              ติดต่อสอบถามโปรดติดต่อ Facebook :
              https://www.facebook.com/freelife.entertainmentgroup
            </div>
            <div class="error-actions">
              <a
                href="https://www.facebook.com/freelife.entertainmentgroup"
                class="btn btn-primary btn-lg"
                ><span class="glyphicon glyphicon-home"></span> Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfflinePage",
};
</script>

<style lang="scss" scoped>
.error-template {
  padding: 40px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}
</style>