<template>
  <div>
    <b-container>
      <div class="auth-group">
        <b-row>
          <b-col md="12">
            <h2 class="text-center text-white text-event">
              Welcome to Freelife Meet the King Festival #1
            </h2>
          </b-col>
          <b-col md="7">
            <img
              src="/images/meeting1.jpg"
              alt=""
              width="100%"
              class="logo-meeting"
            />
          </b-col>
          <b-col md="5">
            <div class="text-center">
              <div class="mt-5 auth-group">
                <div class="alert alert-info" role="alert">
                  <h5>
                    ขณะนี้ Freelife - Meet The King Festival #1
                    <b>เปิดลงทะเบียนสำหรับผู้สนใจเข้าร่วมงานเพิ่มเติม สามารถลงทะเบียนเข้าร่วมงานได้ทันที</b>
                  </h5>
                </div>
                <h1 class="text-center text-white">เข้าสู่ระบบ</h1>
                <br />
                <a
                  @click="googleAuth"
                  class="btn btn-block btn-social btn-google"
                >
                  <span class="fa fa-google"></span> เข้าสู่ระบบด้วย Gmail
                </a>
                <a
                  @click="facebookAuth"
                  class="btn btn-block btn-social btn-facebook"
                >
                  <span class="fa fa-facebook"></span> เข้าสู่ระบบด้วย Facebook
                </a>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <br /><br />
  </div>
</template>

<script>
import firebase from "firebase";
import firebaseConfig from "../firebase/config";
const db = firebaseConfig.firestore();

export default {
  name: "Home",
  data() {
    return {
      prepareJoinId: "",
      userData: "",
    };
  },
  components: {},
  beforeMount() {},
  mounted() {},
  methods: {
    async createUniqueId() {
      let randomNumber = Math.floor(10000 + Math.random() * 90000);

      let existNumber = await db
        .collection("meeting1")
        .where("joinId", "==", randomNumber)
        .get()
        .then((joinId) => {
          return joinId;
        });

      if (existNumber.empty) {
        return randomNumber;
      } else {
        this.createUniqueId();
      }
    },
    async facebookAuth() {
      const provide = new firebase.auth.FacebookAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provide)
        .then(async (result) => {
          let userId = result.user.uid;

          let userData = await db
            .collection("users")
            .where("userId", "==", userId)
            .get()
            .then((user) => {
              user.forEach((u) => {
                // console.log(u.id, " => ", u.data());
                this.userData = u.data();
              });
              return user;
            });

          let eventData = await db
            .collection("meeting1")
            .where("userId", "==", userId)
            .get()
            .then((userJoined) => {
              return userJoined;
            });

          if (!userData.empty) {
            if (eventData.empty) {
              this.createUniqueId().then(async (joinid) => {
                await db
                  .collection("meeting1")
                  .add({
                    userId: userId,
                    joinId: joinid,
                    eventName: "Meet The King Festival",
                    userData: this.userData,
                    checkin: 0,
                  })
                  .then(() => {
                    this.$router.push({
                      name: "UserInfo",
                    });
                  });
              });
            } else {
              this.$swal("ยินดีต้อนรับ");
              this.$router.push({
                name: "UserInfo",
              });
            }
          } else {
            this.$router.push({
              name: "Register",
            });
          }
        })
        .catch((e) => {
          this.$swal(e.message);
        });
    },
    async googleAuth() {
      const Firebase = new firebase.auth.GoogleAuthProvider().addScope("email");
      firebase
        .auth()
        .signInWithPopup(Firebase)
        .then(async (result) => {
          let userId = result.user.uid;

          let isAdmin = await db
            .collection("admin")
            .where("email", "==", result.user.email)
            .get()
            .then((adminData) => {
              return adminData;
            });

          if (!isAdmin.empty) {
            this.$router.push({
              name: "Dashboard",
            });
          } else {
            let userData = await db
              .collection("users")
              .where("userId", "==", userId)
              .get()
              .then((user) => {
                user.forEach((u) => {
                  // console.log(u.id, " => ", u.data());
                  this.userData = u.data();
                });
                return user;
              });

            let eventData = await db
              .collection("meeting1")
              .where("userId", "==", userId)
              .get()
              .then((userJoined) => {
                return userJoined;
              });

            if (!userData.empty) {
              if (eventData.empty) {
                this.createUniqueId().then(async (joinid) => {
                  await db
                    .collection("meeting1")
                    .add({
                      userId: userId,
                      joinId: joinid,
                      eventName: "Meet The King Festival",
                      userData: this.userData,
                      checkin: 0,
                    })
                    .then(() => {
                      this.$router.push({
                        name: "UserInfo",
                      });
                    });
                });
              } else {
                this.$swal("Welcome");
                this.$router.push({
                  name: "UserInfo",
                });
              }
            } else {
              this.$router.push({
                name: "Register",
              });
            }
          }
        })

        .catch((e) => {
          console.log(e);
          this.$swal(e.message);
        });
    },
  },
};
</script>

<style lang="scss">
.text-event {
  margin-bottom: 5%;
}
.logo-meeting {
  border: 2px solid #ffffff;
}
.auth-group {
  margin-top: 120px;
}
@media only screen and (max-width: 768px) {
  .logo-meeting {
    width: 100%;
  }
  .auth-group {
    margin-top: 25px;
  }
}
</style>
