<template>
  <div>
    <b-navbar toggleable="lg" type="dark" class="bg-primary">
      <b-navbar-brand href="#">Admin FreeLife</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item>
            <router-link to="/backoffice/dashboard"> Member </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/backoffice/checkin"> Checkin </router-link>
          </b-nav-item>

          <!-- <router-link to="/backoffice/checkin"> Checkin </router-link> -->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item>Logout</b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container>
      <br />
      <b-row>
        <b-col md="8">
          <div align="center">
            <h2>เช็กอินโดยสแกน QR</h2>
            <b-button variant="success" @click="restartCamera"
              >เปิดกล้อง</b-button
            >
          </div>
          <br />

          <div v-if="isCamera">
            <qrcode-stream @decode="onDecode"></qrcode-stream>
          </div>
        </b-col>

        <b-col md="4">
          <b-form inline v-on:submit.prevent="manualCheckin">
            <div align="center">
              <h2>เช็กอินโดย JoinID</h2>
              <b-input v-model="mjoinId"></b-input>
              <b-button @click="manualCheckin" variant="primary"
                >ยืนยัน</b-button
              >
            </div>
          </b-form>
        </b-col>
      </b-row>

      <!-- <qrcode-drop-zone></qrcode-drop-zone> -->
      <!-- <qrcode-capture></qrcode-capture> -->
    </b-container>
  </div>
</template>
<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import firebase from "firebase";
import firebaseConfig from "../firebase/config";
const db = firebaseConfig.firestore();

export default {
  name: "adminCheckin",
  data() {
    return {
      isCamera: true,
      mjoinId: "",
    };
  },
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },
  methods: {
    restartCamera() {
      this.isCamera = true;
    },
    async manualCheckin() {
      this.$swal("ต้องการยืนยันเข้ารวมงานบุคคลนี้").then((ok) => {
        if (ok.isConfirmed) {
          db.collection("meeting1")
            .where("joinId", "==", Number.parseInt(this.mjoinId))
            .get()
            .then((snapshotData) => {
              let userInfo;
              if (!snapshotData.empty) {
                snapshotData.forEach((doc) => {
                  userInfo = doc.data();
                  doc.ref.update({
                    checkin: 1,
                  });
                });
                this.$swal({
                  title: "ตรวจสอบเข้าร่วมงานแล้ว",
                  html: `
                    ชื่อ : ${userInfo.userData.name} 
                    <br> เบอร์โทร : ${userInfo.userData.phoneNo} 
                    <br> ขนาดเสื้อ ${userInfo.userData.shirtSize}
                    <br> ลงทะเบียนย้อนหลัง : ${userInfo.userSpare || "ไม่ใช่"}
                    `,
                });
              } else {
                this.$swal("ไม่พบหมายเลขนี้");
              }
            });
        }
      });
    },
    async onDecode(decodedString) {
      this.$swal("ต้องการยืนยันเข้ารวมงานบุคคลนี้").then((ok) => {
        if (ok.isConfirmed) {
          db.collection("meeting1")
            .where("userId", "==", decodedString)
            .get()
            .then((snapshotData) => {
              let userInfo;
              if (!snapshotData.empty) {
                snapshotData.forEach((doc) => {
                  userInfo = doc.data();
                  doc.ref.update({
                    checkin: 1,
                  });
                  this.$swal({
                    title: "ตรวจสอบเข้าร่วมงานแล้ว",
                    html: `
                    ชื่อ : ${userInfo.userData.name} 
                    <br> เบอร์โทร : ${userInfo.userData.phoneNo} 
                    <br> ขนาดเสื้อ ${userInfo.userData.shirtSize}
                    <br> ลงทะเบียนย้อนหลัง : ${userInfo.userSpare || "ไม่ใช่"}
                    `,
                  });
                });
              } else {
                this.$swal("ไม่พบผู้ใช้นี้");
              }
            });
        } else {
          this.isCamera = false;
        }
      });
    },
  },
};
</script>