import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueThailandAddress from 'vue-thailand-address';
import JsonCSV from 'vue-json-csv'
 


import 'bootswatch/dist/darkly/bootstrap.min.css'
import 'vue-thailand-address/dist/vue-thailand-address.css';
import './assets/scss/main.scss'
import './assets/scss/bootstrap-social.css'




Vue.config.productionTip = false

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueThailandAddress)
Vue.use(VueSweetalert2)
Vue.component('downloadCsv', JsonCSV)


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
