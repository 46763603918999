<template>
  <div class="user-info">
    <div class="container" id="attendance-info">
      <h3 class="text-center">
        <img src="/logoMeeting1.jpg" width="100%" alt="" />
        <b>{{ eventName }}</b>
      </h3>
      <div class="mt-4 text-center">
        <h5>Attendance Info</h5>
      </div>
      <hr />
      <div align="center">
        <qrcode-vue :value="value" :size="size" level="H"></qrcode-vue>
      </div>
      <hr />
      <h2
        class="h4 text-center d-block font-weight-bold text-warning text-uppercase"
      >
        ข้อมูลผู้เข้าร่วม
      </h2>
      <h3 class="h6 text-center d-block text-uppercase font-weight-bold">
        {{ eventName }}
      </h3>
      <span class="bottom-line d-block mx-auto mt-3 mb-4"></span>
      <div class="row my-2 mx-auto">
        <div class="col-3 text-right border-right border-dark">ID</div>
        <div class="col-9 pl-4">#{{ userJoinId }}</div>
      </div>
      <div class="row my-2 mx-auto">
        <div class="col-3 text-right border-right border-dark">Name</div>
        <div class="col-9 pl-4">{{ name }}</div>
      </div>
      <div class="row my-2 mx-auto">
        <div class="col-3 text-right border-right border-dark">Email</div>
        <div class="col-9 pl-4">{{ email }}</div>
      </div>
      <div class="row my-2 mx-auto">
        <div class="col-3 text-right border-right border-dark">Phone</div>
        <div class="col-9 pl-4">{{ phone }}</div>
      </div>

      <p class="mt-5 text-center">
        <b>หมายเหตุ : </b>โปรดเก็บข้อมูลนี้ไว้สำหรับการยืนยันตัวตน
        สำหรับเข้าร่วมงาน
      </p>
      <hr />
    </div>
    <div class="text-center">
      <b-button class="mt-1" variant="outline-info" @click="genImg">
        <i class="fa fa-download" aria-hidden="true"></i>
        ดาวน์โหลด
      </b-button>
      &nbsp;
      <b-button @click="logout" class="mt-1" variant="outline-danger"
        ><i class="fa fa-sign-out" aria-hidden="true"></i> ออกจากระบบ</b-button
      >
    </div>
    <br />
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { firstMeeting } from "../firebase/config";
import firebase from "firebase";
import firebaseConfig from "../firebase/config";
const db = firebaseConfig.firestore();

export default {
  name: "UserInfo",
  data() {
    return {
      value: "",
      eventName: "",
      userJoinId: "",
      name: "",
      email: "",
      phone: "",
      size: 200,
    };
  },
  beforeMount() {
    this.getuserData();
  },
  components: {
    QrcodeVue,
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch();
    },
    async getuserData() {
      await firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) {
          this.$router.replace({
            name: "Home",
          });
        } else {
          let userId = user.uid;
          await db
            .collection("meeting1")
            .where("userId", "==", userId)
            .get()
            .then((userData) => {
              if (!userData.empty) {
                userData.forEach((u) => {
                  this.eventName = u.data().eventName;
                  this.value = u.data().userId;
                  this.userJoinId = u.data().joinId;
                  this.name = u.data().userData.name;
                  this.email = u.data().userData.email;
                  this.phone = u.data().userData.phoneNo;
                });
              } else {
                this.$swal("Please login again.");
                this.$router.push({
                  name: "Home",
                });
              }
            });
        }
      });
    },
    genImg() {
      var node = document.getElementById("attendance-info");

      htmlToImage
        .toPng(node, {
          backgroundColor: "#FFF",
          pixelRatio: 1,
        })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = `freelife-attendance.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.user-info {
  color: #000;
  background: rgb(255, 255, 255);
}
</style>